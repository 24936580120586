import React from 'react';

import { Article, ArticleItem, ArticleList, ArticleSummaryList, ArticleSummaryListItem } from '../../components/Article';

function PrivacyPolicy() {
  return (
    <>
      <h1 className="text-2xl font-extrabold tracking-tight text-blue-900 sm:text-3xl">Privacyverklaring</h1>

      <ArticleList withoutNumbers>
        <Article>
          <ArticleItem>
            Offsoo B.V. (“Offsoo”, “wij”, “ons”, of “onze”), gevestigd in Nederland en ingeschreven in het handelsregister van de Kamer van
            Koophandel onder nummer 82696233, is verantwoordelijk voor deze privacyverklaring.
          </ArticleItem>
          <ArticleItem>
            In deze privacyverklaring leest u alles over de manier waarop uw gegevens worden verzameld en hoe daarmee omgegaan wordt. Zo
            wordt uitgelegd waar deze gegevens opgeslagen liggen en voor welke doelen uw gegevens opgeslagen worden.
          </ArticleItem>
          <ArticleItem>
            Daarnaast vindt u hier ook al uw rechten met betrekking tot uw gegevens en hoe u gebruik kunt maken van die rechten. De
            privacyverklaring zal soms gewijzigd worden door bijvoorbeeld wetswijzigingen. Het is daarom raadzaam de verklaring periodiek te
            raadplegen. Deze privacyverklaring is laatst bijgewerkt op 1 februari 2021.
          </ArticleItem>
        </Article>

        <Article title="Persoonsgegevens die wij verwerken">
          <ArticleItem>
            Wij verzamelen de informatie over u en enige andere partijen waarvan u de gegevens aan ons verstrekt, waaronder persoonlijk
            identificeerbare informatie, zoals uw namen en e-mailadres. De volgende persoonsgegevens worden verwerkt door Offsoo:
            <ArticleSummaryList>
              <ArticleSummaryListItem>Bedrijfsnaam</ArticleSummaryListItem>
              <ArticleSummaryListItem>Adres</ArticleSummaryListItem>
              <ArticleSummaryListItem>Volledige naam contactpersoon</ArticleSummaryListItem>
              <ArticleSummaryListItem>E-mailadres contactpersoon</ArticleSummaryListItem>
              <ArticleSummaryListItem>Telefoonnummer contactpersoon</ArticleSummaryListItem>
              <ArticleSummaryListItem>Bankrekeningnummer en overige betalingsgegevens</ArticleSummaryListItem>
              <ArticleSummaryListItem>
                Persoonsgegevens die u zelf actief verstrekt, bijvoorbeeld door contact met ons op te nemen
              </ArticleSummaryListItem>
              <ArticleSummaryListItem>
                Overige gegevens die door u in het systeem worden ingevuld, zoals adressen, contacten, betaalgegevens en wachtwoorden
              </ArticleSummaryListItem>
            </ArticleSummaryList>
          </ArticleItem>
          <ArticleItem>
            Daarnaast verzamelen we het internetprotocoladres (IP-adres) dat wordt gebruikt om uw computer of mobiele apparaat met internet
            te verbinden; evenals andere informatie over deze verbinding, zoals informatie over het (mobiele) besturingssysteem dat u
            gebruikt, het type internetbrowser dat u gebruikt, uw unieke apparaat-ID en andere diagnostische gegevens.
          </ArticleItem>
        </Article>

        <Article title="Met welk doel en op basis van welke grondslag wij persoonsgegevens verwerken">
          <ArticleItem>
            Wij gebruiken enkele van deze persoonsgegevens om contact met u op te nemen en om de overeengekomen diensten aan u te kunnen
            leveren.
          </ArticleItem>
          <ArticleItem>
            Wij gebruiken de naam, het emailadres en het telefoonnummer van contractpersonen om met hen te communiceren over de door ons te
            verstrekken diensten.
          </ArticleItem>
          <ArticleItem>
            Uw bankgegevens gebruiken wij voor het innen van automatische incasso’s of ter verificatie van uw identiteit.
          </ArticleItem>
          <ArticleItem>
            Verder gebruiken wij softwaretools om informatie over uw gebruik van de website en het softwarepakket te verzamelen, zoals de
            duur van het gebruik van de website, responstijden van pagina’s, informatie over pagina-interactie en methoden die worden
            gebruikt om weg te bladeren van pagina’s.
          </ArticleItem>
          <ArticleItem>
            Wij kunnen uw gegevens ook gebruiken voor (externe) advertentiedoeleinden. Wij zullen uw persoonlijke data slechts voor
            advertentiedoeleinden gebruiken indien u vooraf heeft aangegeven dat u dat wilt. Als u dergelijke berichten niet langer van ons
            wenst te ontvangen, kunt u contact met ons opnemen via de contactgegevens zoals vermeld aan het einde van deze
            privacyverklaring.
          </ArticleItem>
          <ArticleItem>
            Wij verwerken geen van uw persoonsgegevens voor andere doeleinden en enkel met uw uitdrukkelijke toestemming dan wel ter
            uitvoering van de overeenkomst die wij met u zijn aangegaan.
          </ArticleItem>
        </Article>

        <Article title="Delen van persoonsgegevens met derden">
          <ArticleItem>
            Enkel als dat noodzakelijk is voor de levering van de door u aangevraagde diensten, geven wij de door u verstrekte gegevens door
            aan derde partijen.
          </ArticleItem>
          <ArticleItem>Wij maken gebruik van een derde partij, Mollie, voor het incasseren van automatische incasso’s.</ArticleItem>
          <ArticleItem>
            De door ons verzamelde gegevens worden opgeslagen op servers van DigitalOcean. Wij maken hier gebruik van voor het verzorgen van
            de hosting en de opslag van onze database.
          </ArticleItem>
          <ArticleItem>
            Wij maken gebruik van de advertentieplatforms Google Ads en Facebook Ads voor bovengenoemde advertentiedoeleinden. We zijn
            geïnteresseerd in het tonen van advertenties die voor u interessant zijn en daarnaast willen wij onze diensten en onze website
            verbeteren. Tevens maken wij hiervoor gebruik van een aantal analytische tools. Voor een uitgebreide uitleg over welke platforms
            en tools wij gebruiken en de wijze waarop daarvoor cookies worden gebruikt verwijzen wij u naar onze cookieverklaring.
          </ArticleItem>
          <ArticleItem>
            Wanneer wij uw gegevens aan een derde verstrekken, zorgen wij er middels een overeenkomst voor dat de gegevens niet voor andere
            doeleinden worden gebruikt. Ook komen wij hierin overeen dat uw gegevens zullen worden verwijderd zodra ze niet meer nodig zijn.
          </ArticleItem>
          <ArticleItem>
            Verder zullen wij de door u verstrekte gegevens niet aan andere partijen verstrekken, tenzij dit wettelijk verplicht is.
          </ArticleItem>
        </Article>

        <Article title="Hoe lang wij persoonsgegevens bewaren">
          <ArticleItem>
            Uw gegevens worden voor langere tijd door ons bewaard, maar nooit langer dan nodig is om de doelen te realiseren waarvoor uw
            gegevens worden verzameld, tenzij we op grond van een wettelijke regeling uw gegevens langer moeten bewaren.
          </ArticleItem>
        </Article>

        <Article title="Kinderen jonger dan 16 jaar">
          <ArticleItem>
            Wij hebben niet de intentie gegevens te verzamelen over bezoekers van onze diensten en/of website die jonger zijn dan 16 jaar,
            tenzij ze toestemming hebben van ouders of voogd. We kunnen echter niet controleren of een bezoeker ouder dan 16 is. Wij raden
            ouders en voogden dan ook aan betrokken te zijn bij de onlineactiviteiten van hun kinderen, om zo te voorkomen dat er gegevens
            over kinderen verzameld worden zonder ouderlijke toestemming. Als u van mening bent dat wij zonder die toestemming persoonlijke
            gegevens hebben verzameld over een minderjarige, neem dan contact met ons op via het adres onderaan deze pagina, dan verwijderen
            wij deze informatie.
          </ArticleItem>
        </Article>

        <Article title="Hoe wij persoonsgegevens beveiligen">
          <ArticleItem>
            Offsoo neemt de bescherming van uw gegevens serieus en neemt passende beveiligingsmaatregelen om misbruik, verlies, onbevoegde
            toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan.
          </ArticleItem>
          <ArticleItem>
            Met eerdergenoemde derde partijen die uw persoonsgegevens verwerken hebben wij de afspraak gemaakt dat zij de nodige technische
            en organisatorische maatregelen ter bescherming van de persoonsgegevens zullen nemen.
          </ArticleItem>
          <ArticleItem>
            Als u het idee heeft dat uw gegevens toch niet goed beveiligd zijn of er aanwijzingen zijn van misbruik, neem dan contact op met
            ons via onze contactgegevens zoals vermeld onderaan deze pagina.
          </ArticleItem>
        </Article>

        <Article title="Gegevens inzien, aanpassen of verwijderen">
          <ArticleItem>
            U heeft het recht om uw persoonsgegevens in te zien, te corrigeren of te verwijderen. Daarnaast heeft u het recht om uw
            eventuele toestemming voor de gegevensverwerking in te trekken of bezwaar te maken tegen de verwerking van uw persoonsgegevens
            door Offsoo. Ook heeft u het recht op gegevensoverdraagbaarheid. Dat betekent dat u bij ons een verzoek kunt indienen om uw
            persoonsgegevens waarover wij beschikken in een computerbestand naar u of een ander, door u genoemde organisatie, te sturen.
          </ArticleItem>
          <ArticleItem>
            U kunt een dergelijk verzoek richten aan het adres onderaan deze pagina. Om er zeker van te zijn dat het verzoek door u is
            gedaan, vragen wij u een kopie van uw identiteitsbewijs met het verzoek mee te sturen, waarbij de pasfoto, de nummers onderaan
            het paspoort, identiteitsbewijsnummer en BSN onleesbaar zijn gemaakt.
          </ArticleItem>
          <ArticleItem>We reageren zo snel mogelijk, maar uiterlijk binnen vier weken, op uw verzoek.</ArticleItem>
        </Article>

        <Article title="Vragen en contact">
          <ArticleItem>
            Bij vragen of opmerkingen over de verwerking van uw persoonsgegevens of deze privacyverklaring kunt u contact met ons opnemen
            via het volgende emailadres:&nbsp;
            <a target="_blank" rel="noreferrer" href="mailto:support@offsoo.net">
              support@offsoo.net
            </a>
            .
          </ArticleItem>
        </Article>
      </ArticleList>
    </>
  );
}

export default PrivacyPolicy;
