import React from 'react';

import { Article, ArticleItem, ArticleList } from '../../components/Article';

function CookiePolicy() {
  return (
    <>
      <h1 className="text-2xl font-extrabold tracking-tight text-blue-900 sm:text-3xl">Cookiebeleid</h1>

      <ArticleList withoutNumbers>
        <Article>
          <ArticleItem>
            Offsoo B.V. (“Offsoo”, “wij”, “ons”, of “onze”), gevestigd in Nederland en ingeschreven in het handelsregister van de Kamer van
            Koophandel onder nummer 82696233, is verantwoordelijk voor deze cookieverklaring.
          </ArticleItem>
          <ArticleItem>
            In deze cookieverklaring leest u alles over de manier waarop cookies worden verzameld en hoe daarmee omgegaan wordt.
          </ArticleItem>
          <ArticleItem>
            De cookieverklaring zal soms gewijzigd worden door bijvoorbeeld wetswijzigingen. Het is daarom raadzaam de verklaring periodiek
            te raadplegen. Deze cookieverklaring is laatst bijgewerkt op 1 februari 2021.
          </ArticleItem>
        </Article>

        <Article title="Cookies">
          <ArticleItem>
            Wij maken gebruik van cookies en vergelijkbare technologieën (“cookies”) om onze diensten en de website te laten functioneren,
            het gebruik daarvan te helpen analyseren, onze dienstverlening te verbeteren of voor marketing doeleinden.
          </ArticleItem>
          <ArticleItem>
            Een cookie is een klein tekstbestand dat bij het eerste bezoek aan onze diensten wordt opgeslagen op uw computer, tablet of
            smartphone. De cookies die wij gebruiken zorgen voor een goede technische werking van de website en gebruiksgemak. Dergelijke
            cookies verzekeren dat de website en softwarepakketten naar behoren werken en onthouden bijvoorbeeld uw voorkeursinstellingen.
          </ArticleItem>
          <ArticleItem>Hieronder wordt beschreven van welke cookies gebruik worden gemaakt.</ArticleItem>
        </Article>

        <Article title="Functionele cookies">
          <ArticleItem>
            Functionele cookies zijn noodzakelijk voor het functioneren van de softwarepakketten en de website. Zonder deze cookies kunnen
            bepaalde onderdelen niet worden gebruikt. Deze cookies worden anoniem gebruikt en hebben dus weinig impact op uw privacy.
          </ArticleItem>
        </Article>

        <Article title="Sessie cookies">
          <ArticleItem>
            Met behulp van een sessie cookie kunnen wij zien welke onderdelen van de softwarepakketten en de website u hebt bekeken. Wij
            kunnen onze diensten daardoor zoveel mogelijk aanpassen op het surfgedrag van onze gebruikers en bezoekers. Deze cookies worden
            automatisch verwijderd zodra u uw webbrowser afsluit.
          </ArticleItem>
        </Article>

        <Article title="Google Analytics">
          <ArticleItem>
            Via Google Analytics wordt op de website geanonimiseerd gegevens over het bezoekgedrag verzameld. Zo kunnen wij zien hoe
            bezoekers door de website navigeren. Wij gebruiken deze informatie om (de werking van) onze diensten en website te
            optimaliseren.
          </ArticleItem>
          <ArticleItem>
            Google kan deze informatie aan derden verschaffen indien Google hiertoe wettelijk wordt verplicht, of voor zover derden de
            informatie namens Google verwerken. Wij hebben hier geen invloed op. Wij hebben Google niet toegestaan de verkregen analytische
            informatie te gebruiken voor andere Google-diensten.
          </ArticleItem>
          <ArticleItem>
            De informatie die Google verzamelt wordt zo veel mogelijk geanonimiseerd. Uw IP-adres wordt nadrukkelijk niet meegegeven. De
            informatie wordt overgebracht naar en door Google opgeslagen op servers in de Verenigde Staten. Google is aangesloten bij het
            Privacy Shield-programma van het Amerikaanse Ministerie van Handel.
          </ArticleItem>
          <ArticleItem>
            Indien u niet wilt dat uw bezoekgedrag wordt bijgehouden kunt u een plug-in van Google downloaden die dit voorkomt. Voor
            Microsoft Internet Explorer 11, Google Chrome, Mozilla Firefox, Apple Safari en Opera is deze plug-in&nbsp;
            <a target="_blank" rel="noreferrer" href="https://tools.google.com/dlpage/gaoptout?hl=nl">
              hier
            </a>
            &nbsp;beschikbaar. Deze instelling geldt dan voor alle websites die worden bezocht, dus niet alleen voor deze website.
          </ArticleItem>
        </Article>

        <Article title="Google Ads, Facebook Ads en remarketing">
          <ArticleItem>
            De advertentieplatforms Google Ads en Facebook Ads maken gebruik van cookies voor het tonen van voor u interessante advertenties
            en het verzamelen van informatie daarover. Hiervoor wordt gebruik gemaakt van ‘ad server cookies’, waarmee het succes van
            advertenties kan worden gemeten. Als u onze website via een dergelijke advertentie bezoekt, slaan deze platforms een cookie op
            uw apparaat op. Deze cookies verlopen gewoonlijk na 30 dagen en zijn niet bedoeld om u persoonlijk te identificeren. Voor deze
            cookies wordt informatie over uw bezoek en de getoonde advertenties opgeslagen. Hierbij kunnen Google en Facebook uw
            internetbrowser en eventuele eerdere cookies herkennen. We ontvangen slechts statistische evaluaties van Google en Facebook, op
            basis van deze informatie kunnen we gebruikers niet identificeren. We verzamelen hierbij dan ook geen persoonsgegevens. Wel
            wordt er automatisch een directe verbinding tussen uw browser en de servers van deze platforms gemaakt. Als u bent geregistreerd
            bij een dienst van Google of Facebook, kan uw bezoek aan uw account gekoppeld worden.
          </ArticleItem>
          <ArticleItem>
            We maken ook gebruik van de Adwords Remarketing Pixel en Facebook Pixel waardoor we aan gebruikers van onze websiteadvertenties
            kunnen presenteren op basis van bij Google en Facebook bekende interesses. De interactie van gebruikers op onze website kan zo
            ook worden gebruikt om bezoekers op andere websites van gerichte reclame te voorzien. Wij gebruiken gegevens die we ontvangen
            over het gedrag van bezoekers van onze website ook om onze diensten te verbeteren.
          </ArticleItem>
        </Article>

        <Article title="Overige webanalyse diensten">
          <ArticleItem>
            Naast voornoemde diensten maken we tevens gebruik van de webanalyseservice Mixpanel. Hierdoor kunnen we nagaan hoe bezoekers
            gebruik maken van onze website en softwarepakket. Mixpanel gebruiken we om te kunnen analyseren hoe gebruikers gebruik maken van
            onze softwarepakketten. Daarnaast is het met behulp van deze dienst ook mogelijk om rechtstreeks feedback te verzamelen van
            gebruikers van de website. Wij gebruiken deze gegevens om onze diensten te verbeteren.
          </ArticleItem>
        </Article>

        <Article title="Verwijderen van cookies">
          <ArticleItem>
            U kunt u afmelden voor cookies door uw internetbrowser zo in te stellen dat deze geen cookies meer opslaat. Daarnaast kunt u ook
            alle informatie die eerder is opgeslagen via de instellingen van uw browser verwijderen. Het is echter mogelijk dat u in dat
            geval niet langer alle functies van onze website of de softwarepakketten kunt gebruiken.
          </ArticleItem>
        </Article>

        <Article title="Vragen en contact">
          <ArticleItem>
            Bij vragen of opmerkingen over de verwerking van deze cookie verklaring kunt u contact met ons opnemen via het volgende
            emailadres:&nbsp;
            <a target="_blank" rel="noreferrer" href="mailto:support@offsoo.net">
              support@offsoo.net
            </a>
            .
          </ArticleItem>
        </Article>
      </ArticleList>
    </>
  );
}

export default CookiePolicy;
