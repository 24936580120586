import axios, { AxiosInstance } from 'axios';
import { api } from './constants';

const ApiClient = <AxiosInstance>axios.create({
  baseURL: api.url,
  withCredentials: true,
  headers: {
    'Access-Control-Allow-Origin': '*'
  }
});

export default ApiClient;
