import Pusher from 'pusher-js';
import { api, pusher } from './constants';

const PusherClient = new Pusher(pusher.key, {
  cluster: pusher.cluster,
  forceTLS: true,
  authEndpoint: `${api.url}/register/broadcasting/auth`
});

export default PusherClient;
