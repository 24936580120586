const app = {
  domain: process.env.GATSBY_APP_DOMAIN
};

const api = {
  url: process.env.GATSBY_API_BASE_URL || '/api'
};

const recaptcha = {
  key: process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY || ''
};

const pusher = {
  key: process.env.GATSBY_PUSHER_APP_KEY || '',
  cluster: process.env.GATSBY_PUSHER_APP_CLUSTER || 'eu'
};

const intercom = {
  key: process.env.GATSBY_INTERCOM_APP_KEY || ''
};

export { api, app, intercom, pusher, recaptcha };
