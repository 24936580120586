import clsx from 'clsx';
import React from 'react';
import { ArticleItemProps } from './types';

function ArticleItem({ articleNumber = 1, number = 1, withoutNumbers = false, children }: ArticleItemProps) {
  return (
    <div className="my-2 flex">
      {!withoutNumbers && <strong className="w-6 shrink-0 font-medium">{`${articleNumber}.${number}`}</strong>}
      <div className={clsx({ 'pl-6': !withoutNumbers })}>{children}</div>
    </div>
  );
}

export default ArticleItem;
