import { Dialog, DialogPanel } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useRef } from 'react';

type DialogProps = {
  fullWidth?: boolean;
  fullHeight?: boolean;
  noPadding?: boolean;
  noBackground?: boolean;
  showClose?: boolean;
  small?: boolean;
  open: boolean;
  onClose: (open: boolean) => void;
  children: React.ReactNode;
};

function DefaultDialog({
  fullWidth = false,
  fullHeight = false,
  noPadding = false,
  noBackground = false,
  showClose = false,
  small = false,
  open,
  onClose,
  children
}: DialogProps) {
  const closeButtonRef = useRef(null);

  return (
    <AnimatePresence>
      {open && (
        <Dialog static open={open} onClose={onClose} className="relative z-50">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-gray-500/75 backdrop-blur-sm"
          />
          <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
            <DialogPanel
              as={motion.div}
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              className={clsx(
                'relative inline-block transform overflow-hidden rounded-lg text-left align-bottom transition-all sm:w-full sm:align-middle',
                {
                  'w-full !max-w-none': fullWidth,
                  'h-full': fullHeight,
                  'bg-white shadow-xl': !noBackground,
                  'px-4 pb-4 pt-5 sm:p-6': !noPadding && !fullHeight,
                  'sm:max-w-lg': small,
                  'sm:max-w-4xl': !small
                }
              )}
            >
              {showClose && (
                <div className="absolute right-6 top-3">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-500 hover:bg-gray-100 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500 focus:ring-offset-white"
                    onClick={() => onClose(false)}
                    ref={closeButtonRef}
                  >
                    <span className="sr-only">Sluiten</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              )}

              <div
                className={clsx({
                  'h-full overflow-y-auto': fullHeight
                })}
              >
                {children}
              </div>
            </DialogPanel>
          </div>
        </Dialog>
      )}
    </AnimatePresence>
  );
}

export default DefaultDialog;
