import React, { ReactNode, useMemo, useState } from 'react';
import DialogContext, { DialogModel, RequestDemoModel } from './DialogContext';

import Dialog from '../../components/Dialog';
import { RequestDemo } from '../../features/Form';
import { CookiePolicy, PrivacyPolicy, TermsAndConditions } from '../../features/Legal';

type DialogProviderProps = {
  children: ReactNode;
};

function DialogProvider({ children }: DialogProviderProps) {
  const [{ type, isOpen, user }, setDialog] = useState<DialogModel>({
    isOpen: false
  });

  const openRequestDemo = (data: RequestDemoModel) => {
    setDialog({
      type: 'RequestDemo',
      isOpen: true,
      user: data.user
    });
  };

  const openTermsAndConditions = () => {
    setDialog({
      type: 'TermsAndConditions',
      isOpen: true
    });
  };
  const openPrivacyPolicy = () => {
    setDialog({
      type: 'PrivacyPolicy',
      isOpen: true
    });
  };
  const openCookiePolicy = () => {
    setDialog({
      type: 'CookiePolicy',
      isOpen: true
    });
  };

  const close = () => {
    setDialog({ isOpen: false });
  };

  const contextValue = useMemo(
    () => ({
      type,
      isOpen,
      setDialog,
      openRequestDemo,
      openTermsAndConditions,
      openPrivacyPolicy,
      openCookiePolicy,
      close
    }),
    [type, isOpen]
  );

  return (
    <DialogContext.Provider value={contextValue}>
      {children}

      <Dialog open={type === 'RequestDemo' && isOpen} onClose={close} noBackground noPadding showClose fullHeight fullWidth>
        <RequestDemo user={user} />
      </Dialog>

      <Dialog
        open={['TermsAndConditions', 'PrivacyPolicy', 'CookiePolicy'].indexOf(type || '') > -1 && isOpen}
        onClose={close}
        fullHeight
        showClose
      >
        <div className="prose-primary prose p-6 sm:max-w-screen-xl sm:p-8 md:p-10 lg:p-12">
          {type === 'TermsAndConditions' && <TermsAndConditions />}
          {type === 'PrivacyPolicy' && <PrivacyPolicy />}
          {type === 'CookiePolicy' && <CookiePolicy />}
        </div>
      </Dialog>
    </DialogContext.Provider>
  );
}

export default DialogProvider;
