import React, { Children, cloneElement, isValidElement } from 'react';
import { ArticleItemProps, ArticleProps } from './types';

function Article({ title = '', number = 1, withoutNumbers = false, children }: ArticleProps) {
  return (
    <section>
      {(!withoutNumbers || title) && (
        <h3 className="space-x-1">
          {!withoutNumbers && <span className="text-blue-900">{`Artikel ${number}:`}</span>}
          {title && <span className="font-bold text-primary-600">{title}</span>}
        </h3>
      )}

      <div>
        {Children.toArray(children).map((child, index) => {
          if (isValidElement<ArticleItemProps>(child)) {
            return cloneElement(child, {
              articleNumber: number,
              number: number && index + 1,
              withoutNumbers
            });
          }

          return null;
        })}
      </div>
    </section>
  );
}

export default Article;
