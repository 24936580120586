import React, { Children, cloneElement, isValidElement } from 'react';
import { ArticleSummaryListItemProps, ArticleSummaryListProps } from './types';

function ArticleSummaryList({ children }: ArticleSummaryListProps) {
  return (
    <div className="my-1 text-gray-700">
      {Children.toArray(children).map((child, index) => {
        if (isValidElement<ArticleSummaryListItemProps>(child)) {
          return cloneElement(child, {
            type: `${String.fromCharCode(index + 97)}`
          });
        }

        return null;
      })}
    </div>
  );
}

export default ArticleSummaryList;
