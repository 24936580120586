import React from 'react';
import { ArticleSummaryListItemProps } from './types';

function ArticleSummaryListItem({ type = 'a', children }: ArticleSummaryListItemProps) {
  return (
    <div className="my-1 flex">
      <strong className="w-6 shrink-0 font-medium">{`${type}.`}</strong>
      <span>{children}</span>
    </div>
  );
}

export default ArticleSummaryListItem;
