import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError, AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import ApiClient from '../../Common/api-client';
import { initReCaptcha, setResponseError } from '../../Common/api-helper';

import { FromDataProps } from './types';

import Alert from '../../../components/Alert';
import { Button } from '../../../components/Button';
import Input from '../../../components/Input';
import { TermsAndConditions } from '../../Checkbox';

interface PasswordFormProps {
  defaultValues: FromDataProps;
  onPrevious: ({}: FromDataProps) => void;
  onNext: ({}: FromDataProps) => void;
}

const PasswordFormSchema = yup.object().shape({
  terms: yup
    .boolean()
    .required('Het accepteren van de voorwaarden is verplicht.')
    .oneOf([true], 'Het accepteren van de voorwaarden is verplicht.'),
  password: yup
    .string()
    .required('Wachtwoord is verplicht.')
    .min(8, 'Wachtwoord moet minimaal 8 karakters bevatten.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      'Wachtwoord moet minimaal één letter, één hoofdletter, één cijfer en één speciaal karakter bevatten.'
    ),
  passwordConfirmation: yup
    .string()
    .required('Wachtwoord bevestiging is verplicht.')
    .oneOf([yup.ref('password')], 'Wachtwoorden zijn niet hetzelfde.')
});

function PasswordForm({ defaultValues, onPrevious, onNext }: PasswordFormProps) {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const {
    register,
    handleSubmit,
    getValues,
    setFocus,
    setError,
    formState: { errors }
  } = useForm<FromDataProps>({
    defaultValues,
    resolver: yupResolver(PasswordFormSchema)
  });

  useEffect(() => {
    setFocus('password');
  }, [setFocus]);

  const onPreviousClick = (): void => {
    onPrevious(getValues());
  };

  const onSubmit = (formData: FromDataProps): void => {
    setLoading(true);

    initReCaptcha().then(token =>
      ApiClient.post('register', { ...formData, token })
        .then(({ data: responseData }: AxiosResponse) => {
          window.dataLayer.push({
            event: 'working_lead',
            lead_status: 'Request Trial - Third Step',
            eventId: responseData?.eventId,
            externalId: responseData?.hash,
            referral_source: formData.referralSourceExplanation || formData.referralSource || null,
            user: { email: formData.email, phone: formData.phone, first_name: formData.firstName, last_name: formData.lastName }
          });

          onNext({ ...formData, ...responseData });
        })
        .catch((error: AxiosError<{ message?: string }>) => setResponseError(error, setErrorMessage, setError))
        .finally(() => setLoading(false))
    );
  };

  return (
    <form className="form" noValidate onSubmit={handleSubmit(onSubmit)}>
      {errorMessage && <Alert message={errorMessage} type="error" />}

      <h2 className="mb-1 text-lg font-bold text-blue-900 sm:text-2xl">Stel jouw wachtwoord in</h2>
      <p className="mb-4 text-sm text-gray-500">
        Kies hieronder een wachtwoord, met dit wachtwoord en het opgegeven e-mailadres kan je hierna inloggen.
      </p>

      <div className="mt-1">
        <Input
          {...register('password')}
          type="password"
          id="register-password"
          autoComplete="password"
          placeholder="Wachtwoord..."
          labelText="Wachtwoord"
          required
          disabled={loading}
          error={!!errors.password}
          helperText={errors.password && errors.password.message}
        />
      </div>

      <div className="mt-1">
        <Input
          {...register('passwordConfirmation')}
          type="password"
          id="register-password-confirmation"
          autoComplete="password_confirmation"
          placeholder="Bevestig wachtwoord..."
          labelText="Bevestig wachtwoord"
          required
          disabled={loading}
          error={!!errors.passwordConfirmation}
          helperText={errors.passwordConfirmation && errors.passwordConfirmation.message}
        />
      </div>

      <div className="mt-1">
        <TermsAndConditions id="register-terms" register={register} errors={errors} loading={loading} />
      </div>

      <div className="mt-4 md:flex md:justify-between">
        <Button type="submit" color="primary" large loading={loading}>
          Omgeving aanmaken
        </Button>

        <Button onClick={onPreviousClick} color="light" large disabled={loading}>
          Terug
        </Button>
      </div>
    </form>
  );
}

export default PasswordForm;
