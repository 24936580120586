import clsx from 'clsx';
import { Link } from 'gatsby';
import React from 'react';

import { ButtonProps } from './types';

function ButtonLoading(): JSX.Element {
  return React.createElement(
    'svg',
    {
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 24 24',
      className: 'animate-spin -ml-1 mr-3 h-5 w-5',
      fill: 'none'
    },
    React.createElement('circle', {
      cx: 12,
      cy: 12,
      r: 10,
      className: 'opacity-25',
      stroke: 'currentColor',
      strokeWidth: 4
    }),
    React.createElement('path', {
      d: 'M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z',
      className: 'opacity-75',
      fill: 'currentColor'
    })
  );
}

function Button({
  href,
  onClick,
  type,
  color = 'primary',
  filled = false,
  large = false,
  uppercase = false,
  loading = false,
  disabled = false,
  className,
  children = undefined
}: ButtonProps & React.ComponentProps<'button'>) {
  const isDisabled = disabled || !!loading;
  const classes = clsx(
    'transition duration-100 tracking-wide inline-flex items-center justify-center shrink-0 w-full rounded font-medium no-underline md:w-auto focus:outline-none',

    {
      'cursor-not-allowed opacity-70': isDisabled,
      'focus:ring-2 focus:ring-inset focus:ring-primary-500': !isDisabled,
      'text-sm py-2 px-3': !large,
      'text-sm py-4 px-4': large,
      uppercase,
      shadow: filled
    },

    {
      'bg-primary-500 text-primary-100': color === 'primary' && isDisabled,
      'bg-primary-600 hover:bg-primary-700 text-white': color === 'primary' && !isDisabled
    },
    {
      'bg-primary-50 text-primary-300': color === 'primary-light' && isDisabled,
      'bg-primary-50 hover:bg-primary-100 text-primary-800 hover:text-primary-900': color === 'primary-light' && !isDisabled
    },
    {
      'bg-gray-50 text-primary-300': color === 'light' && isDisabled,
      'bg-white hover:bg-gray-50 text-primary-600': color === 'light' && !isDisabled
    },
    {
      'bg-blue-500 text-blue-100': color === 'dark' && isDisabled,
      'bg-blue-800 hover:bg-blue-900 text-white': color === 'dark' && !isDisabled
    },

    className
  );

  if (disabled) {
    return <span className={classes}>{children}</span>;
  }

  if (href) {
    return (
      <Link className={classes} to={(!isDisabled && href) || '/'}>
        {loading && <ButtonLoading />}
        {children}
      </Link>
    );
  }

  return (
    <button className={classes} type={type === 'button' ? 'button' : 'submit'} onClick={onClick} disabled={isDisabled}>
      {loading && <ButtonLoading />}
      {children}
    </button>
  );
}

export default Button;
