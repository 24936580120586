import clsx from 'clsx';
import React from 'react';

import Button from './Button';
import { ButtonProps } from './types';

function RegisterButton({ href = undefined, children = undefined, ...props }: ButtonProps & React.ComponentProps<'button'>) {
  // const element = document.getElementById('register-by-email-email')

  // registerFormRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  // document.getElementById('register-by-email-email')?.focus({ preventScroll: true })

  return (
    <>
      <div
        className={clsx({
          'hidden lg:block': false
        })}
      >
        <Button href={href || '/aanmelden'} {...props}>
          {children}
        </Button>
      </div>
      {/* {element && (
        <div className="lg:hidden">
          <Button onClick={() => callback()} {...props}>
            {children}
          </Button>
        </div>
      )} */}
    </>
  );
}

export default RegisterButton;
