import React from 'react';

import { Article, ArticleItem, ArticleList, ArticleSummaryList, ArticleSummaryListItem } from '../../components/Article';

function TermsAndConditions() {
  return (
    <>
      <h1 className="text-2xl font-extrabold tracking-tight text-blue-900 sm:text-3xl">Algemene voorwaarden</h1>

      <ArticleList>
        <Article title="Toepasselijkheid">
          <ArticleItem>
            Deze algemene voorwaarden (“Algemene Voorwaarden”) zijn van toepassing op alle aanbiedingen en alle Overeenkomsten inzake de
            dienstverlening van Offsoo B.V., gevestigd in Nederland en ingeschreven in het handelsregister van de Kamer van Koophandel onder
            nummer 82696233 (“Offsoo”).
          </ArticleItem>
          <ArticleItem>
            Bepalingen die afwijken van deze Algemene Voorwaarden maken alleen deel uit van de tussen partijen gesloten Overeenkomst indien
            en voor zover partijen dat uitdrukkelijk schriftelijk zijn overeengekomen.
          </ArticleItem>
          <ArticleItem>
            Offsoo is gerechtigd de Algemene Voorwaarden eenzijdig te wijzigen. Indien er grote inhoudelijke wijzigingen zullen worden
            doorgevoerd in de Algemene Voorwaarden zal Offsoo de Klant daarvan minimaal een maand voor ingang van de wijzigingen op de
            hoogte stellen.
          </ArticleItem>
        </Article>

        <Article title="Overeenkomst">
          <ArticleItem>
            Er komt een overeenkomst (“Overeenkomst”) tot stand op het moment dat er een proef- en maandabonnement wordt afgesloten door een
            klant (“de Klant”) voor een van de softwarepakketten die Offsoo aanbiedt (“Software”). Op deze Overeenkomst zijn de Algemene
            Voorwaarden van toepassing.
          </ArticleItem>
          <ArticleItem>
            Indien enige aanvaarding van de Klant afwijkt van de door Offsoo aangeboden abonnementen komt er geen Overeenkomst tot stand,
            tenzij partijen uitdrukkelijk schriftelijk anders zijn overeengekomen.
          </ArticleItem>
          <ArticleItem>
            Aanvullingen of wijzigingen op de Algemene Voorwaarden of anderszins wijzigingen of aanvullingen op de Overeenkomst worden eerst
            na schriftelijke bevestiging door Offsoo bindend.
          </ArticleItem>
        </Article>

        <Article title="Abonnement">
          <ArticleItem>
            De Klant kan een proefabonnement van 14 dagen afsluiten voor het gebruik van de Software. Het proefabonnement zal na 14 dagen,
            met uitzondering van schriftelijke opzegging, automatisch worden omgezet in een betaald maandabonnement, tenzij de Klant geen
            betaalgegevens heeft ingevuld of het voor Offsoo niet mogelijk was om de betaalgegevens van de Klant te valideren. Binnen deze
            termijn van 14 dagen zal Offsoo de door de Klant ingevulde bankgegevens proberen te valideren door eenmalig 1 eurocent (0,01
            Euro) af te schrijven van de bankrekening van de Klant.
          </ArticleItem>
          <ArticleItem>
            De prijs van een maandabonnement is afhankelijk van het gekozen softwarepakket en wordt berekend op grond van het aantal
            gebruikers. Mocht het aantal gebruikers naderhand gewijzigd worden, dan zal dit gevolgen hebben voor de prijs. De door Offsoo
            gegeven prijzen zijn onder voorbehoud van typfouten, waarvoor geen aansprakelijkheid wordt aanvaard.
          </ArticleItem>
          <ArticleItem>
            Het maandelijkse abonnement kan op elk willekeurig moment ingaan. Het abonnement wordt stilzwijgend verlengd en kan maandelijks
            op elk willekeuring moment worden opgezegd per email of door het abonnement stop te zetten onder ‘mijn account’ in de
            gebruikersomgeving van de Klant. Eenmaal betaalde abonnementsgelden worden niet gerestitueerd.
          </ArticleItem>
          <ArticleItem>
            De door Offsoo gehanteerde prijzen van de abonnementen zijn exclusief BTW en eventuele kosten. Deze kosten kunnen onder meer
            bestaan uit administratiekosten en retributies welke van overheidswege worden opgelegd, tenzij uitdrukkelijk schriftelijk anders
            wordt vermeld.
          </ArticleItem>
          <ArticleItem>
            Offsoo behoudt zich het recht voor de abonnementsprijs gedurende de looptijd van het abonnement te wijzigen. Offsoo zal de Klant
            minimaal een (1) maand voor de ingang van een prijswijziging schriftelijk op de hoogte stellen. Indien de Klant niet akkoord
            gaat met de prijswijziging, is de Klant gerechtigd de Overeenkomst binnen 14 dagen na de kennisgeving van de prijswijziging op
            te zeggen. De Overeenkomst eindigt dan op de datum waarop de nieuwe prijs gaat gelden.
          </ArticleItem>
          <ArticleItem>
            De Klant draagt er zorg voor dat alle gegevens, waarvan Offsoo aangeeft of waarvan de Klant redelijkerwijs behoort te begrijpen
            dat deze noodzakelijk zijn voor het uitvoeren van de Overeenkomst, tijdig aan Offsoo worden verstrekt. Indien de benodigde
            gegevens niet tijdig en/of geheel aan Offsoo worden verstrekt, heeft Offsoo het recht de uitvoering van de Overeenkomst op te
            schorten en/of de uit de vertraging voortvloeiende extra kosten volgens de gebruikelijke tarieven aan de Klant in rekening te
            brengen.
          </ArticleItem>
        </Article>

        <Article title="Facturering en betaling">
          <ArticleItem>
            Offsoo stuurt de Klant na afloop van de betreffende maand een factuur voor het maandelijkse abonnement. De factuur zal naar het
            door de Klant opgegeven emailadres verzonden worden.
          </ArticleItem>
          <ArticleItem>
            Offsoo is gerechtigd de facturen op elektronische wijze per e-mail aan de Klant toe te zenden. Indien de Klant een factuur per
            post wenst te ontvangen, behoudt Offsoo zich het recht voor om dat verzoek af te wijzen of de meerkosten daarvan aan de Klant in
            rekening te brengen.
          </ArticleItem>
          <ArticleItem>
            Betaling van de factuur dient per automatische incasso plaats te vinden. De Klant geeft Offsoo toestemming om het in rekening
            gebrachte bedrag binnen 14 dagen na verzending van de factuur af te schrijven. De afschrijving zal worden verricht door Mollie
            Payments.
          </ArticleItem>
          <ArticleItem>
            Indien het factuurbedrag niet van de bankrekening van de Klant kan worden afgeschreven, zal Offsoo de Klant een email sturen met
            het verzoek de factuur alsnog binnen 7 dagen te voldoen.
          </ArticleItem>
          <ArticleItem>
            Indien de Klant de factuur niet binnen de in het vorige artikellid omschreven betaaltermijn heeft voldaan, is de Klant van
            rechtswege in verzuim. De Klant is in een dergelijk geval wettelijke rente alsmede buitengerechtelijke incassokosten aan Offsoo
            verschuldigd. Offsoo is gerechtigd alle kosten die gemaakt moeten worden om het verschuldigde bedrag te incasseren, met inbegrip
            van kosten voor juridische bijstand, op de Klant te verhalen.
          </ArticleItem>
          <ArticleItem>
            Indien de Klant de factuur niet binnen de in lid 4 van dit artikel omschreven betaaltermijn heeft voldaan, is Offsoo tevens
            gerechtigd de nakomingen van haar verplichtingen op te schorten totdat de Klant het factuurbedrag volledig heeft voldaan. De
            opschorting van de verplichtingen kan onder meer bestaan uit het afsluiten van de toegang van de Klant tot de Software.
          </ArticleItem>
          <ArticleItem>
            Enig bezwaar van de Klant tegen de hoogte van de factuur of de geleverde diensten schort de betalingsverplichting van de Klant
            niet op.
          </ArticleItem>
        </Article>

        <Article title="Licentie">
          <ArticleItem>
            Offsoo verleent aan de Klant een niet-exclusieve en niet-overdraagbare tijdelijke licentie voor het gebruik van het door de
            Klant gekozen softwarepakket voor de duur van de Overeenkomst. De Klant is op basis van de licentie gerechtigd het
            softwarepakket te gebruiken op basis van het door de Klant aantal opgegeven gebruikers. Overschrijding van het aantal actieve
            gebruikers zal door de Klant onmiddellijk aan Offsoo worden gemeld. Een dergelijke overschrijding zal financieel worden
            afgehandeld conform het bepaalde in artikel 3.2.
          </ArticleItem>
          <ArticleItem>
            De licentie kent de volgende beperkingen:
            <ArticleSummaryList>
              <ArticleSummaryListItem>
                Het is de Klant niet toegestaan de Software in gebruik te geven aan derden of deze ten behoeve van derden te gebruiken.
              </ArticleSummaryListItem>
              <ArticleSummaryListItem>Het is de Klant niet toegestaan de Software te wijzigen of aan te passen.</ArticleSummaryListItem>
              <ArticleSummaryListItem>
                Het is de Klant slechts toegestaan enige bij de Software ontvangen documentatie te verveelvoudigen tot het aantal opgegeven
                gebruikers. De gemaakte kopieën mogen vervolgens slechts door het eigen personeel van de Klant voor intern gebruik worden
                aangewend.
              </ArticleSummaryListItem>
              <ArticleSummaryListItem>
                Het is de Klant niet toegestaan de broncode van de Software te ontvangen of te pogen deze door middel van reverse
                engineering te reconstrueren, de Software te kopiëren of te dupliceren, de Software te wijzigen of te proberen afgeleide
                werken van de Software te reproduceren of voor te bereiden.
              </ArticleSummaryListItem>
              <ArticleSummaryListItem>
                Het is de Klant niet toegestaan om inbreuk te plegen op intellectuele eigendomsrechten van derden.
              </ArticleSummaryListItem>
              <ArticleSummaryListItem>
                Het is de Klant niet toegestaan om informatie te verspreiden, toegankelijk te maken en/of aan te bieden met een
                pornografische inhoud waaronder inbegrepen, doch niet uitsluitend, webpagina’s met pornografische afbeeldingen, banners die
                reclame maken voor andere webpagina’s met een dergelijke inhoud of de distributie van toegangscodes voor voornoemde
                websites.
              </ArticleSummaryListItem>
              <ArticleSummaryListItem>
                Het is de Klant niet toegestaan om via de Software van Offsoo aan te sporen tot dan wel zich bezig te houden met illegale
                activiteiten c.q. activiteiten die schadelijk kunnen zijn voor de server van Offsoo dan wel voor enige andere server
                aangesloten op het internet waaronder, doch niet uitsluitend, verwijzingen naar c.q. het aanbieden van zogenaamde
                ‘pirate’-software, ‘hacker’-programma’s, -archieven of ‘warez’-sites.
              </ArticleSummaryListItem>
              <ArticleSummaryListItem>
                Het is de Klant niet toegestaan om zich op een andere wijze schuldig te maken aan enig strafbaar feit, of zich op de een of
                andere wijze onrechtmatig te gedragen jegens Offsoo en/of derden.
              </ArticleSummaryListItem>
            </ArticleSummaryList>
          </ArticleItem>
          <ArticleItem>
            Indien niet aan de in dit artikel genoemde verplichtingen is voldaan, is Offsoo gerechtigd de Overeenkomst met onmiddellijke
            ingang geheel of gedeeltelijk te ontbinden dan wel de uitvoering van de Overeenkomst op te schorten tot het moment dat de Klant
            wel aan deze verplichtingen heeft voldaan. De kosten in verband met de opgelopen vertraging dan wel de kosten voor het
            verrichten van extra werkzaamheden dan wel de overige hieruit voortvloeiende gevolgen zijn voor rekening en risico van de Klant.
          </ArticleItem>
          <ArticleItem>
            Offsoo is gerechtigd te onderzoeken of de Klant de Software gebruikt op een wijze die overeenstemt met de voorwaarden van de
            deze Overeenkomst. De Klant verplicht zich aan een dergelijke audit mee te werken.
          </ArticleItem>
        </Article>

        <Article title="Garanties">
          <ArticleItem>
            Offsoo garandeert dat er alleen personeel wordt inzet dat beschikt over de overeengekomen dan wel voor het verrichten van de
            diensten benodigde vaardigheden en kwalificaties, rekening houdend met de aard van de te leveren diensten en de wijze waarop
            Offsoo zich als deskundige heeft gepresenteerd.
          </ArticleItem>
          <ArticleItem>
            Offsoo garandeert dat gebreken voor eigen rekening worden hersteld. Indien de Klant een beroep wil doen op deze garantie, stelt
            hij Offsoo daarvan schriftelijk en in spoedgevallen telefonisch op de hoogte. Offsoo herstelt gebreken zo spoedig mogelijk
            rekening houdend met de ernst en de aard daarvan. Dergelijk herstel kan ook plaatsvinden door middel van een probleem
            vermijdende restrictie of door het verstrekken van een update.
          </ArticleItem>
          <ArticleItem>
            Offsoo garandeert niet dat de Software zonder onderbrekingen, fouten of gebreken zal werken. Voor de werking van de Software
            alsook het herstellen van gebreken is Offsoo afhankelijk van leveranciers. Offsoo is dan ook niet verantwoordelijk voor
            vertragingen, leveringsfouten of andere gebreken of fouten die een gevolg zijn van de dienstverlening van dergelijke derden. Het
            verzenden van gegevens en informatie via datacommunicatielijnen zoals telefoonlijnen, kabel of draadloze verbindingen, geschiedt
            onder verantwoordelijkheid en voor risico van de partij die zich van die communicatiemethode bedient.
          </ArticleItem>
          <ArticleItem>
            Een overeengekomen levertijd is indicatief en geldt nimmer als fatale termijn. Indien de overeengekomen levertijd wordt
            overschreden of dreigt te worden overschreden, zal Offsoo de Klant daarvan in kennis stellen.
          </ArticleItem>
          <ArticleItem>
            De garantie van dit artikel 6 vervalt indien het desbetreffende gebrek is ontstaan door foutherstel, onderhoud of wijzigingen
            uitgevoerd door of namens de Klant.
          </ArticleItem>
        </Article>

        <Article title="Data">
          <ArticleItem>
            De door de Klant in de Software ingevoerde data is eigendom van de Klant. De Klant is eigenaar van alle rechten, aanspraken op
            en belangen in en op alle data en is als enige verantwoordelijk voor deze gegevens, inclusief de kwaliteit, nauwkeurigheid,
            betrouwbaarheid en integriteit daarvan.
          </ArticleItem>
          <ArticleItem>
            Offsoo zal zorgdragen voor regelmatige periodieke back-ups van de data die door de Klant in de Software is ingevoerd. In geval
            van verlies of beschadiging van deze gegevens is de enige en exclusieve remedie van de Klant tegen Offsoo dat Offsoo commercieel
            redelijke inspanningen zal verrichten om de verloren gegane of beschadigde gegevens van de Klant te herstellen op basis van de
            laatste back-up die Offsoo van dergelijke gegevens van de Klant heeft gemaakt.
          </ArticleItem>
          <ArticleItem>
            Voor zover de data persoonsgegevens bevatten in de zin van de EU Algemene Verordening Gegevensbescherming (“AVG”), treedt Offsoo
            op als verwerker en de Klant als verwerkingsverantwoordelijke in de zin van de AVG. Met betrekking tot de verwerking van
            persoonsgegevens verplichten partijen zich ertoe:
            <ArticleSummaryList>
              <ArticleSummaryListItem>
                Te voldoen aan alle toepasselijke wet- en regelgeving, inclusief alle toepasselijke gegevensprivacywetten en te voldoen aan
                alle richtlijnen of beleidslijnen die van toepassing kunnen zijn op de gegevens.
              </ArticleSummaryListItem>
              <ArticleSummaryListItem>
                De personen van wie de gegevens worden verzameld te informeren over hun rechten en indien nodig hun toestemming vragen
                volgens de toepasselijke wetgeving inzake gegevens privacy.
              </ArticleSummaryListItem>
              <ArticleSummaryListItem>
                Alle passende technische en organisatorische voorzorgsmaatregelen te treffen om de vertrouwelijkheid van data te beschermen
                en openbaarmaking van data anders dan voorzien in deze Algemene Voorwaarden te voorkomen.
              </ArticleSummaryListItem>
              <ArticleSummaryListItem>
                Zo spoedig mogelijk na ingang van de Overeenkomst een verwerkersovereenkomst met elkaar aan te gaan.
              </ArticleSummaryListItem>
            </ArticleSummaryList>
          </ArticleItem>
        </Article>

        <Article title="Overdracht">
          <ArticleItem>
            Het is Offsoo toegestaan derden in te schakelen bij de uitvoering van deze Overeenkomst. Voor het overige is het partijen niet
            toegestaan de rechten en/of verplichtingen uit deze Overeenkomst aan een derde over te dragen, zonder voorafgaande schriftelijke
            toestemming van de andere partij.
          </ArticleItem>
        </Article>

        <Article title="Aansprakelijkheid">
          <ArticleItem>
            De partij die toerekenbaar tekortschiet jegens de andere partij en/of een onrechtmatige daad jegens de andere partij begaat, is
            aansprakelijk voor vergoeding van de door die partij geleden en/of te lijden schade.
          </ArticleItem>
          <ArticleItem>
            De Klant kan alleen aanspraak maken op vergoeding van schade als bedoeld onder artikel 9.1 ter zake van een toerekenbare
            tekortkoming indien de Klant Offsoo door middel van een schriftelijke kennisgeving in gebreke stelt en nakoming binnen de
            gestelde termijn uitblijft. De verplichting tot schriftelijke ingebrekestelling vervalt indien nakoming of herstel reeds
            blijvend onmogelijk is.
          </ArticleItem>
          <ArticleItem>
            De aansprakelijkheid uit hoofde van artikel 9.1 van Offsoo jegens de Klant is beperkt tot het totale bedrag aan
            abonnementskosten dat is betaald onder deze Overeenkomst gedurende een periode van 12 maanden voorafgaand aan de gebeurtenis
            waardoor de schade is veroorzaakt.
          </ArticleItem>
          <ArticleItem>
            In geen geval zal Offsoo jegens de Klant aansprakelijk zijn voor enige incidentele, indirecte, bijzondere of gevolgschade, van
            welke aard dan ook en ongeacht hoe de schade is ontstaan, welke voortvloeit door of in verband met de uitvoering van deze
            Overeenkomst, waaronder, maar niet beperkt tot, schade ten gevolge van omzet- of winstderving, bedrijfsstilstand,
            reputatieschade, verminderde goodwill, beschadiging of verlies van gegevens of enige bijzondere of gevolgschade.
          </ArticleItem>
          <ArticleItem>
            Offsoo vrijwaart de Klant tegen aanspraken van derden uit hoofde van inbreuk op door die derden gepretendeerde intellectuele
            eigendomsrechten met betrekking tot aan de Klant geleverde diensten, tenzij de inbreuk is ontstaan door toedoen van de Klant.
          </ArticleItem>
          <ArticleItem>
            De Klant vrijwaart Offsoo ter zake van alle aanspraken van derden met betrekking tot de verwerking van door de Klant of ten
            behoeve van de Klant ter beschikking gestelde data, waaronder persoonsgegevens.
          </ArticleItem>
        </Article>

        <Article title="Overmacht">
          <ArticleItem>
            In geval van overmacht zullen de verplichtingen van Offsoo op grond van deze Algemene Voorwaarden worden opgeschort zolang de
            overmachtstoestand voortduurt.
          </ArticleItem>
          <ArticleItem>
            Offsoo zal de Klant onverwijld schriftelijk, per e-mail of via de website op de hoogte stellen in geval van een
            overmachtssituatie, onder vermelding van de oorzaak en de te verwachten duur daarvan alsook welke bepalingen van de Algemene
            Voorwaarden als gevolg daarvan niet kunnen worden nagekomen.
          </ArticleItem>
          <ArticleItem>
            Onder overmacht wordt verstaan iedere tekortkoming die niet aan Offsoo kan worden toegerekend omdat deze niet is te wijten aan
            de schuld van Offsoo en die ook niet krachtens de wet, rechtshandelingen of in het maatschappelijk verkeer geldende opvattingen
            voor zijn rekening komt, inclusief alle van buiten komende oorzaken die redelijkerwijs niet te voorzien waren, waaronder, maar
            niet uitsluitend, storingen in hardware dan wel software of netwerken en storingen als gevolg van wederrechtelijk handelen van
            derden.
          </ArticleItem>
        </Article>

        <Article title="Intellectueel eigendom">
          <ArticleItem>
            De intellectuele eigendomsrechten met betrekking tot de Software en daaraan gerelateerde documentatie berusten bij Offsoo en/of
            diens licentiegever(s). De Overeenkomst kent de Klant geen enkel recht toe op of in auteursrechten, patenten, databaserechten,
            handelsgeheimen, handelsnamen, handelsmerken, al dan niet geregistreerd, of andere rechten of licenties met betrekking tot de
            Services of de daaraan gerelateerde documentatie, tenzij uitdrukkelijk in de Overeenkomst opgenomen.
          </ArticleItem>
        </Article>

        <Article title="Geheimhouding">
          <ArticleItem>
            Alle informatie die is geleverd door de ene partij aan de andere partij onder deze Overeenkomst, al dan niet aangeduid of
            aangewezen als vertrouwelijk, waaronder, zonder limitatief te zijn, bedrijfsgeheimen, knowhow, technische informatie, broncodes,
            algoritmen, processen, formules, gegevens, computer verwerkingssystemen en technieken, gegevensbestanden, stroomschema’s,
            specificaties, prijslijsten, klantenlijsten, marketingplannen en financiële informatie wordt beschouwd als “Vertrouwelijke
            Informatie”. opgenomen.
          </ArticleItem>
          <ArticleItem>
            Partijen zullen de Vertrouwelijke Informatie strikt geheimhouden en de Vertrouwelijke Informatie niet aan enige derde bekend
            maken of de Vertrouwelijke Informatie gebruiken voor enig ander doel dan de uitvoering van de Overeenkomst.
          </ArticleItem>
          <ArticleItem>
            De verplichtingen van dit artikel gelden niet indien de informatie:
            <ArticleSummaryList>
              <ArticleSummaryListItem>
                algemeen bekend is of wordt anders dan door enig handelen of nalaten van de ontvangende partij;
              </ArticleSummaryListItem>
              <ArticleSummaryListItem>
                vóór de openbaarmaking reeds in het rechtmatige bezit van de andere partij was;
              </ArticleSummaryListItem>
              <ArticleSummaryListItem>
                op rechtmatige wijze door een derde aan de ontvangende partij bekendgemaakt is, zonder beperking ten aanzien van de
                bekendmaking, of;
              </ArticleSummaryListItem>
              <ArticleSummaryListItem>
                onafhankelijk van de verstrekkende partij door de ontvangende partij ontwikkeld is, hetgeen middels schriftelijk bewijs door
                de ontvangende partij kan worden aangetoond;
              </ArticleSummaryListItem>
            </ArticleSummaryList>
          </ArticleItem>
          <ArticleItem>
            Elke partij neemt alle redelijke maatregelen om ervoor te zorgen dat de Vertrouwelijke Informatie van de andere partij niet door
            haar medewerkers en/of voor haar werkzame derden in strijd met deze Algemene Voorwaarden wordt bekendgemaakt of verspreid.
          </ArticleItem>
          <ArticleItem>
            Elke partij is gerechtigd Vertrouwelijke Informatie vrij te geven in het geval dat en voor zover dergelijke Vertrouwelijke
            Informatie op grond van enige wettelijke bepaling, door een overheids- of ander regelgevend orgaan, door een rechtbank of een
            andere bevoegde autoriteit bekend moet worden gemaakt, op voorwaarde dat, voor zover dat wettelijk is toegestaan, het de andere
            partij daarvan zo snel en zoveel als mogelijk op de hoogte zal stellen en, wanneer kennisgeving van de verstrekking hiervan niet
            verboden is en gegeven wordt in overeenstemming met dit artikel 12, het rekening houdt met redelijke verzoeken van de andere
            partij met betrekking tot de inhoud van dergelijke verstrekking.
          </ArticleItem>
        </Article>

        <Article title="Duur overeenkomst">
          <ArticleItem>
            Partijen zijn gerechtigd de Overeenkomst te beëindigen met inachtneming van een opzegtermijn van één maand.
          </ArticleItem>
          <ArticleItem>
            Iedere partij is gerechtigd de Overeenkomst met onmiddellijke ingang geheel buiten rechte te ontbinden door middel van een
            schriftelijke kennisgeving, zonder dat hierdoor enige aansprakelijkheid jegens de andere partij ontstaat en onverminderd enig
            ander recht van de partij die de ontbinding inroept, indien één van de volgende omstandigheden zich voordoet:
            <ArticleSummaryList>
              <ArticleSummaryListItem>het faillissement van de andere partij wordt aangevraagd;</ArticleSummaryListItem>
              <ArticleSummaryListItem>de andere partij wordt in staat van faillissement verklaard;</ArticleSummaryListItem>
              <ArticleSummaryListItem>
                aan de andere partij wordt (al dan niet voorlopig) surseance van betaling verleend;
              </ArticleSummaryListItem>
              <ArticleSummaryListItem>de onderneming van de andere partij wordt beëindigd.</ArticleSummaryListItem>
            </ArticleSummaryList>
          </ArticleItem>
          <ArticleItem>
            In alle andere dan de in artikel 5.3 en 13.2 genoemde gevallen, komt een partij slechts een bevoegdheid tot ontbinding van de
            Overeenkomst toe indien de andere partij, na een deugdelijke en zo gedetailleerd mogelijke schriftelijke ingebrekestelling
            waarbij een redelijke termijn gesteld wordt voor zuivering van de tekortkoming door de partij die toerekenbaar tekortschiet in
            de nakoming van verplichtingen op grond van deze Overeenkomst.
          </ArticleItem>
        </Article>

        <Article title="Overige bepalingen">
          <ArticleItem>
            De artikelen 9 (Aansprakelijkheid), 11 (Intellectuele eigendom), 12 (Geheimhouding) blijven naar hun aard van toepassing na
            beëindiging van de Overeenkomst.
          </ArticleItem>
          <ArticleItem>De Algemene Voorwaarden van de Klant zijn niet van toepassing.</ArticleItem>
          <ArticleItem>
            Indien één of meer van deze bepalingen in deze Algemene Voorwaarden ongeldig, in strijd met de wet of onafdwingbaar zijn, laat
            dit de geldigheid van de overige bepalingen onverlet. Partijen zullen in goed overleg onderhandelen over een nieuwe bepaling,
            ter vervanging van de ongeldige of niet afdwingbare bepaling, die zo veel mogelijk de strekking van de ongeldige of
            onafdwingbare bepaling volgt.
          </ArticleItem>
          <ArticleItem>
            Eventuele mondelinge toezeggingen en afspraken hebben geen werking, tenzij deze schriftelijk door een partij zijn bevestigd.
          </ArticleItem>
          <ArticleItem>
            Indien Offsoo op enig moment nalaat nakoming te verlangen van hetgeen partijen zijn overeengekomen, laat dit onverlet het recht
            van Offsoo op een later tijdstip alsnog nakoming te verlangen.
          </ArticleItem>
          <ArticleItem>Op deze Algemene Voorwaarden en de Overeenkomst tussen partijen is Nederlands recht van toepassing.</ArticleItem>
          <ArticleItem>
            De rechter van het arrondissement Utrecht, Nederland, is bij uitsluiting bevoegd ter zake van ieder geschil tussen partijen dat
            verband houdt met de Overeenkomst tussen partijen en/of de onderhavige Algemene Voorwaarden.
          </ArticleItem>
        </Article>
      </ArticleList>
    </>
  );
}

export default TermsAndConditions;
