import clsx from 'clsx';
import React, { forwardRef } from 'react';

type InputProps = {
  rows?: number;

  labelText: string;
  labelHidden?: boolean;

  adornmentText?: string;
  adornmentPosition?: 'left' | 'right';

  helperText?: string;

  error?: boolean;
};

const Input = forwardRef(
  (
    {
      id,
      rows,
      required = false,
      disabled = false,
      labelText,
      labelHidden = false,
      adornmentText,
      adornmentPosition = 'left',
      helperText,
      error = false,
      ...props
    }: InputProps & React.ComponentProps<'input'>,
    ref
  ) => {
    const inputClass = clsx('block w-full rounded-md shadow-sm focus:ring focus:ring-opacity-50 disabled:opacity-50', {
      'border-gray-300 focus:border-primary-300 focus:ring-primary-200': !error,
      'border-red-500 focus:border-red-300 focus:ring-red-200': error,
      'pl-24': adornmentText && adornmentPosition === 'left',
      'pr-24': adornmentText && adornmentPosition === 'right'
    });

    return (
      <div className="relative">
        <label htmlFor={id} className="block">
          {labelText && (
            <span
              className={clsx('mb-1 block text-sm font-semibold', {
                'sr-only': labelHidden,
                'text-gray-900': !error,
                'text-red-500': error
              })}
            >
              {labelText}
              {required && <span className="text-red-700"> *</span>}
            </span>
          )}

          <div className="relative">
            {adornmentText && (
              <div
                className={clsx('absolute inset-y-0 flex items-center', {
                  'left-0': adornmentPosition === 'left',
                  'right-0': adornmentPosition === 'right'
                })}
              >
                <span className="px-4 py-0 text-gray-400">{adornmentText}</span>
              </div>
            )}

            {rows ? (
              <textarea ref={ref} id={id} rows={rows} required={required} disabled={disabled} {...props} className={inputClass} />
            ) : (
              <input ref={ref} id={id} required={required} disabled={disabled} {...props} className={inputClass} />
            )}
          </div>
        </label>

        <span
          className={clsx('mr-2 block h-5 text-right text-sm font-medium', {
            'text-gray-600': !error,
            'text-red-500': error,
            'mb-6': (helperText || '').length > 50
          })}
        >
          {helperText || ''}
        </span>
      </div>
    );
  }
);

Input.displayName = 'Input';

export default Input;
