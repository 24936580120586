import React, { useContext } from 'react';
import { FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form';

import Checkbox from '../../components/Checkbox';
import DialogContext from '../../providers/Dialog/DialogContext';

interface TermsAndConditionsProps {
  id: string;
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FieldValues>;
  loading: boolean;
}

function TermsAndConditions({ id, register, loading, errors }: TermsAndConditionsProps) {
  const { openTermsAndConditions, openPrivacyPolicy } = useContext(DialogContext);

  return (
    <Checkbox
      {...register('terms')}
      id={id}
      labelText="Ik ga akkoord met de voorwaarden"
      required
      disabled={loading}
      error={!!errors.terms}
      helperText={errors.terms?.message?.toString()}
    >
      <button
        type="button"
        onClick={() => openTermsAndConditions()}
        className="rounded underline transition duration-100 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500"
      >
        Algemene Voorwaarden
      </button>
      {' & '}
      <button
        type="button"
        onClick={() => openPrivacyPolicy()}
        className="rounded underline transition duration-100 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500"
      >
        Privacyverklaring
      </button>
    </Checkbox>
  );
}

export default TermsAndConditions;
