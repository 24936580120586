import clsx from 'clsx';
import React from 'react';

import { XCircleIcon } from '@heroicons/react/24/outline';

import { AlertProps } from './types';

function Alert({ type = 'success', message }: AlertProps) {
  return (
    <div
      className={clsx('mb-6 rounded-md p-4', {
        'bg-green-50': type === 'success',
        'bg-red-50': type === 'error'
      })}
    >
      <div className="flex">
        <div className="shrink-0">
          <XCircleIcon
            className={clsx('h-5 w-5', {
              'text-green-400': type === 'success',
              'text-red-400': type === 'error'
            })}
          />
        </div>
        <div className="ml-3">
          <p
            className={clsx('text-sm font-medium', {
              'text-green-800': type === 'success',
              'text-red-800': type === 'error'
            })}
          >
            {message}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Alert;
