import { GatsbyBrowser } from 'gatsby';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { IntercomProvider } from 'react-use-intercom';

import moment from 'moment';
import 'moment/locale/nl';

import { intercom } from './src/features/Common/constants';
import DialogProvider from './src/providers/Dialog/DialogProvider';

import './static/styles/global.css';

moment.locale('nl');

window.dataLayer = window.dataLayer || [];
window.grecaptcha = window.grecaptcha || {};
window.grecaptcha.ready = cb => {
  if (typeof window.grecaptcha === 'undefined') {
    const c = '___grecaptcha_cfg';
    window[c] = window[c] || {};
    (window[c]['fns'] = window[c]['fns'] || []).push(cb);
  } else {
    cb();
  }
};

// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => (
  <CookiesProvider>
    <IntercomProvider appId={intercom.key} initializeDelay={500} autoBoot autoBootProps={{ hideDefaultLauncher: true }}>
      <DialogProvider>{element}</DialogProvider>
    </IntercomProvider>
  </CookiesProvider>
);

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ location, prevLocation }) => {
  if (process.env.NODE_ENV !== `production` || location.href === prevLocation?.href) {
    return;
  }

  const sendPageView = () => {
    window.dataLayer.push({ event: 'page_view', page_location: location.href, page_title: document.title });
  };

  if (`requestAnimationFrame` in window) {
    requestAnimationFrame(() => {
      requestAnimationFrame(() => setTimeout(sendPageView, 0));
    });
  } else {
    setTimeout(sendPageView, 32);
  }
};
