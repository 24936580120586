import { createContext } from 'react';

export type DialogContextModel = {
  openRequestDemo: (data: RequestDemoModel) => void;
  openTermsAndConditions: () => void;
  openPrivacyPolicy: () => void;
  openCookiePolicy: () => void;
  close: () => void;
};

export type DialogModel = RequestDemoModel & {
  isOpen: boolean;
  type?: 'RequestDemo' | 'Register' | 'TermsAndConditions' | 'PrivacyPolicy' | 'CookiePolicy';
};

export type RequestDemoModel = {
  user?: string | (string | null)[] | null;
};

const DialogContext = createContext<DialogContextModel>({
  openRequestDemo: () => {},
  openTermsAndConditions: () => {},
  openPrivacyPolicy: () => {},
  openCookiePolicy: () => {},
  close: () => {}
});

export default DialogContext;
