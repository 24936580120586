import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError, AxiosResponse } from 'axios';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import ApiClient from '../../Common/api-client';
import { setResponseError } from '../../Common/api-helper';
import { app } from '../../Common/constants';

import { FromDataProps } from './types';

import Alert from '../../../components/Alert';
import { Button } from '../../../components/Button';
import Input from '../../../components/Input';
import Select from '../../../components/Select';

interface CompanyFormProps {
  defaultValues: FromDataProps;
  onPrevious: ({}: FromDataProps) => void;
  onNext: ({}: FromDataProps) => void;
}

const CompanyFormSchema = yup.object().shape({
  company: yup.string().required('Bedrijfsnaam is verplicht.').min(2, 'Bedrijfsnaam moet minimaal 2 karakters bevatten.'),
  domain: yup
    .string()
    .lowercase()
    .required('Domeinnaam is verplicht.')
    .min(4, 'Domeinnaam moet minimaal 4 karakters bevatten.')
    .matches(/^[a-z-]*$/, 'Domeinnaam mag alleen letters en streepjes bevatten.'),
  companySize: yup.string(),
  referralSource: yup.string(),
  referralSourceExplanation: yup.string()
});

function CompanyForm({ defaultValues, onPrevious, onNext }: CompanyFormProps) {
  const [loading, setLoading] = useState(false);
  const [showReferralSourceExplanation, setShowReferralSourceExplanation] = useState(false);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    setFocus,
    setError,
    formState: { errors, dirtyFields, isSubmitted }
  } = useForm<FromDataProps>({
    defaultValues,
    resolver: yupResolver(CompanyFormSchema)
  });

  useEffect(() => {
    setFocus('company');
  }, [setFocus]);

  const onPreviousClick = (): void => {
    onPrevious(getValues());
  };

  const createSlug = (string: string): string =>
    string
      .trim()
      .toLowerCase()
      .replace(/\d+/g, '')
      .replace(/\s+/g, '-')
      .replace(/[^\w-]+/g, '')
      .replace(/-+/g, '-')
      .replace(/^-+/, '')
      .replace(/-+$/, '');

  const setDomain = ({ target }: ChangeEvent & { target: { value: string } }) => {
    const { value: company } = target;
    const { domain: dirtyDomainField } = dirtyFields;

    setValue('company', company, {
      shouldDirty: true,
      shouldValidate: isSubmitted
    });

    if (dirtyDomainField === true) {
      return;
    }

    const currentDomain = getValues('domain');
    const newDomain = createSlug(company || '');

    if (currentDomain !== newDomain) {
      setValue('domain', newDomain, {
        shouldDirty: false,
        shouldValidate: isSubmitted
      });
    }
  };

  const onSubmit = (formData: FromDataProps) => {
    const domain = getValues('domain');

    if (domain !== createSlug(domain || '')) {
      setError(
        'domain',
        {
          type: 'manual',
          message: 'Domeinnaam mag alleen letters en streepjes bevatten.'
        },
        { shouldFocus: true }
      );

      return;
    }

    setLoading(true);

    ApiClient.post('register/company/check', formData)
      .then(({ data: responseData }: AxiosResponse) => {
        window.dataLayer.push({
          event: 'working_lead',
          lead_status: 'Request Trial - Second Step',
          referral_source: formData.referralSourceExplanation || formData.referralSource || null,
          user: { email: formData.email, phone: formData.phone, first_name: formData.firstName, last_name: formData.lastName }
        });

        setErrorMessage(null);
        onNext({ ...formData, ...responseData });
      })
      .catch((error: AxiosError) => setResponseError(error, setErrorMessage, setError))
      .finally(() => setLoading(false));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {errorMessage && <Alert message={errorMessage} type="error" />}

      <h2 className="mb-1 text-lg font-bold text-blue-900 sm:text-2xl">Kies jouw domeinnaam</h2>
      <p className="mb-4 text-sm text-gray-500">
        Elk bedrijf heeft een uniek domein, dit wordt het adres van jouw omgeving. Het domein eindigt altijd op .{app.domain}.
      </p>

      <div>
        <Input
          {...register('company')}
          type="text"
          id="register-company"
          autoComplete="company"
          placeholder="Bedrijfsnaam..."
          labelText="Bedrijfsnaam"
          required
          disabled={loading}
          error={!!errors.company}
          helperText={errors.company && errors.company.message}
          onChange={setDomain}
        />
      </div>
      <div className="mt-1">
        <Input
          {...register('domain')}
          type="text"
          id="register-domain"
          autoComplete="domain"
          placeholder="Domeinnaam..."
          labelText="Domeinnaam"
          required
          disabled={loading}
          error={!!errors.domain}
          helperText={errors.domain && errors.domain.message}
          adornmentText={`.${app.domain}`}
          adornmentPosition="right"
        />
      </div>
      <div className="mt-1">
        <Select
          {...register('companySize')}
          options={['1 medewerker', '2-3 medewerkers', '4-10 medewerkers', '10-25 medewerkers', '25-50 medewerkers', '50+ medewerkers']}
          id="register-companySize"
          autoComplete="company-size"
          placeholder="Bedrijfsgrootte..."
          labelText="Bedrijfsgrootte"
          disabled={loading}
          error={!!errors.companySize}
          helperText={errors.companySize && errors.companySize.message}
        />
      </div>
      <div className="mt-1">
        <Select
          {...register('referralSource')}
          options={[
            'Zoekmachine (Google)',
            'Social Media',
            'Advertenties (Google/Facebook)',
            'Aanbeveling van een kennis/collega',
            'Anders'
          ]}
          id="register-referralSource"
          autoComplete="referral-source"
          placeholder="Selecteer een optie..."
          labelText="Hoe zijn jullie bij ons terecht gekomen?"
          disabled={loading}
          error={!!errors.referralSource}
          helperText={errors.referralSource && errors.referralSource.message}
          onChange={({ target }) => setShowReferralSourceExplanation(target.value === 'Anders')}
        />

        {showReferralSourceExplanation && (
          <Input
            {...register('referralSourceExplanation')}
            type="text"
            id="register-referralSourceExplanation"
            autoComplete="referral-source-explanation"
            placeholder="Namelijk..."
            labelText="Namelijk (toelichting)"
            disabled={loading}
            error={!!errors.referralSourceExplanation}
            helperText={errors.referralSourceExplanation && errors.referralSourceExplanation.message}
          />
        )}
      </div>

      <div className="mt-4 md:flex md:justify-between">
        <Button type="submit" color="primary" large loading={loading}>
          Volgende
        </Button>

        <Button onClick={onPreviousClick} color="light" large disabled={loading}>
          Terug
        </Button>
      </div>
    </form>
  );
}

export default CompanyForm;
