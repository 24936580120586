import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError, AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import ApiClient from '../../Common/api-client';
import { setResponseError } from '../../Common/api-helper';

import { FromDataProps } from './types';

import Alert from '../../../components/Alert';
import { Button } from '../../../components/Button';
import Input from '../../../components/Input';

interface UserFormProps {
  defaultValues: FromDataProps;
  onNext: ({}: FromDataProps) => void;
}

const UserFormSchema = yup.object().shape({
  userId: yup.string(),
  userExternalId: yup.string(),
  firstName: yup.string().required('Voornaam is verplicht.').min(2, 'Voornaam moet minimaal 2 karakters bevatten.'),
  lastName: yup.string().required('Achternaam is verplicht.').min(2, 'Achternaam moet minimaal 2 karakters bevatten.'),
  email: yup
    .string()
    .email('E-mailadres moet een geldig adres zijn.')
    .when(['userId', 'userExternalId'], {
      is: (userId: any, userExternalId: any) => !userId && !userExternalId,
      then: schema => schema.required('E-mailadres is verplicht.')
    }),
  phone: yup.string().max(128, 'Telefoonnummer mag maximaal 128 karakters bevatten.')
});

function UserForm({ defaultValues, onNext }: UserFormProps) {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const {
    register,
    handleSubmit,
    setFocus,
    setError,
    formState: { errors }
  } = useForm<FromDataProps>({
    defaultValues,
    resolver: yupResolver(UserFormSchema)
  });

  useEffect(() => {
    setFocus('firstName');
  }, [setFocus]);

  const onSubmit = (formData: FromDataProps): void => {
    setLoading(true);

    ApiClient.post('register/user/check', formData)
      .then(({ data: responseData }: AxiosResponse) => {
        window.dataLayer.push({
          event: 'working_lead',
          lead_status: 'Request Trial - First Step',
          user: { email: formData.email, phone: formData.phone, first_name: formData.firstName, last_name: formData.lastName }
        });

        onNext({ ...formData, ...responseData });
      })
      .catch((error: AxiosError) => setResponseError(error, setErrorMessage, setError))
      .finally(() => setLoading(false));
  };

  return (
    <form className="form" noValidate onSubmit={handleSubmit(onSubmit)}>
      {errorMessage && <Alert message={errorMessage} type="error" />}

      <h2 className="mb-1 text-lg font-bold text-blue-900 sm:text-2xl">Account aanmaken</h2>
      <p className="mb-4 text-sm text-gray-500">
        In drie stappen is de demo-omgeving aangemaakt, in deze omgeving kan je Offsoo 14 dagen gratis uitproberen.
      </p>

      <div className="space-y-1">
        <div className="space-y-1 lg:flex lg:flex-row lg:space-x-4 lg:space-y-0">
          <div className="lg:flex-1">
            <Input
              {...register('firstName')}
              type="text"
              id="register-firstName"
              autoComplete="firstname"
              placeholder="Voornaam..."
              labelText="Voornaam"
              required
              disabled={loading}
              error={!!errors.firstName}
              helperText={errors.firstName && errors.firstName.message}
            />
          </div>

          <div className="lg:flex-1">
            <Input
              {...register('lastName')}
              type="text"
              id="register-lastName"
              autoComplete="lastname"
              placeholder="Achternaam..."
              labelText="Achternaam"
              required
              disabled={loading}
              error={!!errors.lastName}
              helperText={errors.lastName && errors.lastName.message}
            />
          </div>
        </div>

        {!defaultValues.userId && !defaultValues.userExternalId && (
          <Input
            {...register('email')}
            type="email"
            id="register-email"
            autoComplete="email"
            placeholder="E-mailadres..."
            labelText="E-mailadres"
            disabled={loading}
            error={!!errors.email}
            helperText={errors.email && errors.email.message}
          />
        )}

        <Input
          {...register('phone')}
          type="text"
          id="register-phone"
          autoComplete="phone"
          placeholder="Telefoonnummer..."
          labelText="Telefoonnummer"
          disabled={loading}
          error={!!errors.phone}
          helperText={errors.phone && errors.phone.message}
        />
      </div>

      <div className="mt-4">
        <Button type="submit" color="primary" large loading={loading}>
          Volgende
        </Button>
      </div>
    </form>
  );
}

export default UserForm;
