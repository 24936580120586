import { captureMessage } from '@sentry/browser';
import { AxiosError } from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { FieldValues, UseFormSetError } from 'react-hook-form';

import { recaptcha } from './constants';

const initReCaptcha = () =>
  new Promise(resolve => {
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(recaptcha?.key, { action: 'submit' }).then((token: string) => resolve(token));
    });
  });

const setResponseError = (
  error: AxiosError,
  setMessage: Dispatch<SetStateAction<string | null>>,
  setFieldMessage?: UseFormSetError<FieldValues>
) => {
  const { response } = error;
  const { status: responseStatus, data: responseData } = response || {};

  captureMessage(error?.message || 'Response error.', {
    level: 'error',
    contexts: {
      API: {
        Url: error?.config?.url,
        Method: error?.config?.method?.toUpperCase()
      },
      Response: {
        Code: responseStatus,
        Message: responseData?.message
      }
    }
  });

  switch (responseStatus) {
    case 404:
      setMessage('Niet gevonden.');
      break;

    case 422:
      let message = null;

      if (setFieldMessage) {
        Object.keys(responseData?.errors).forEach(key => {
          setFieldMessage(key, {
            type: 'manual',
            message: responseData?.errors?.[key]?.[0]
          });
        });
      } else {
        message = 'Er is iets fout gegaan.';
      }

      // eslint-disable-next-line no-underscore-dangle
      if (typeof responseData?.errors?._message?.[0] === 'string') {
        // eslint-disable-next-line no-underscore-dangle
        message = responseData?.errors?._message?.[0];
      }

      setMessage(message);

      break;

    case 429:
      setMessage('Er zijn te veel mislukte pogingen gedaan, probeer het later opnieuw.');
      break;

    default:
      setMessage('Er is iets fout gegaan, probeer het later opnieuw.');
      break;
  }
};

export { initReCaptcha, setResponseError };
