import clsx from 'clsx';
import React, { forwardRef } from 'react';

type CheckboxProps = {
  labelText: string;
  helperText?: string;
  error?: boolean;
};

const Checkbox = forwardRef(
  (
    {
      id,
      required = false,
      disabled = false,
      labelText,
      helperText = undefined,
      error = false,
      children,
      ...props
    }: CheckboxProps & React.ComponentProps<'input'>,
    ref
  ) => (
    <div className="relative">
      <div className="flex items-start">
        <div className="flex h-5 items-center">
          <input
            ref={ref}
            id={id}
            required={required}
            disabled={disabled}
            type="checkbox"
            {...props}
            className={clsx('h-4 w-4 rounded text-primary-600', {
              'border-gray-300 focus:ring-primary-500': !error,
              'border-red-500 focus:ring-red-500': error
            })}
          />
        </div>
        <div className="ml-3 text-sm">
          <label
            htmlFor={id}
            className={clsx('font-medium', {
              'text-gray-700': !error,
              'text-red-500': error
            })}
          >
            {labelText}
            {required && <span className="text-red-700"> *</span>}
          </label>
          <p className="text-gray-500">{children}</p>
        </div>
      </div>

      <span
        className={clsx('mr-2 block h-5 text-right text-sm font-medium', {
          'text-gray-600': !error,
          'text-red-500': error,
          'mb-6': (helperText || '').length > 50
        })}
      >
        {helperText || ''}
      </span>
    </div>
  )
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
