import React, { Children, cloneElement, isValidElement } from 'react';
import { ArticleListProps, ArticleProps } from './types';

function ArticleList({ withoutNumbers = false, children }: ArticleListProps) {
  return (
    <>
      {Children.toArray(children).map((child, index) => {
        if (isValidElement<ArticleProps>(child)) {
          return cloneElement(child, {
            number: index + 1,
            withoutNumbers
          });
        }

        return null;
      })}
    </>
  );
}

export default ArticleList;
