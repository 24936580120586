exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aanmelden-tsx": () => import("./../../../src/pages/aanmelden.tsx" /* webpackChunkName: "component---src-pages-aanmelden-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-juridisch-algemene-voorwaarden-tsx": () => import("./../../../src/pages/juridisch/algemene-voorwaarden.tsx" /* webpackChunkName: "component---src-pages-juridisch-algemene-voorwaarden-tsx" */),
  "component---src-pages-juridisch-cookiebeleid-tsx": () => import("./../../../src/pages/juridisch/cookiebeleid.tsx" /* webpackChunkName: "component---src-pages-juridisch-cookiebeleid-tsx" */),
  "component---src-pages-juridisch-privacyverklaring-tsx": () => import("./../../../src/pages/juridisch/privacyverklaring.tsx" /* webpackChunkName: "component---src-pages-juridisch-privacyverklaring-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */)
}

